<template>
  <div class="wrap">
    <header-nav :navSelIndex="8"></header-nav>
    <div class="wrap-con">
      <!-- 轮播图 -->
      <b-carousel id="carouselExampleControls" :interval="4000" controls indicators background="#ababab">
        <b-carousel-slide v-for="item in banners" v-bind:key="item.banner_path" :img-src="item.banner_path">
        </b-carousel-slide>
        <div class="search-box">
          <div class="search-input-box">
            <div class="dropdown ">
              <button class="btn btn-secondary dropdown-toggle search-select" type="button" id="dropdownMenuButton"
                data-toggle="dropdown" aria-expanded="false">
                {{selectInfo.title}}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#" v-for="(item,index) in selectInfoList" :key="index"
                  @click="selectInfo=item">{{item.title}}</a>
              </div>
            </div>
            <div class="search-split-box">
              <img src="@/../public/icon/search-split@2x.png" class="search-split" />
            </div>
            <input v-model="keyWords" class="search-input-info" :placeholder="searchPlaceholder" @keyup.enter="search" />
      
            <div class="search-split-box margin-left-auto">
              <img src="@/../public/icon/product-distingush@2x.png" class="search-icon" />
            </div>
            <div class="search-split-box">
              <img src="@/../public/icon/search-split@2x.png" class="search-split" />
            </div>
            <div class="search-split-box margin-right-22" @click="search()">
              <img src="@/../public/icon/search-icon@2x.png" class="search-icon" />
            </div>
          </div>
          <div class="key-words-box">
            <span class="key-words-title">{{$t("homeSearchPlaceholder")}}:</span>
            <span class="key-words" v-for="(item,index) in keyWordsList" :key="index"
              @click="keyWords=item;search()">{{item}}</span>
          </div>
        </div>
      </b-carousel>
      <div class="classify-list">
        <ul>
          <li v-for="(item, index) in classifyList" :key="index" @click="toItemLink(item.link)">
            <img :src="item.img" alt="">
          </li>
        </ul>
      </div>
      <div class="package-wrap">
        <div class="package-title">拎包入住套餐化，懒人经济时代的主流装修模式</div>
        <div class="package-swiper">
          <div class="swiper-container package-con">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in packageSlide" :key="index" @click="toPackageDetail(item)">
                <div class="pic">
                  <img :src="item.lroom_img" alt="">
                </div>
                <div class="detial">
                  <div class="name">{{item.setmeal_name}}</div>
                  <div class="info">{{item.hurse_type}} | {{item.style_name}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-button-next package-slide-next"></div>
          <div class="swiper-button-prev package-slide-prev"></div>
        </div>
        <div class="more-btn" @click="toMorePackage">查看更多套餐</div>
      </div>
      <div class="package-wrap">
        <div class="package-title">自主搭配，定制私人空间生活</div>
        <div class="product-wrap">
          <ul>
            <li v-for="(item, index) in customizationItems" :key="index" @click="toProductDetail(item)">
              <div class="pic">
                <img :src="item.file_path" alt="">
              </div>
              <div class="detail">
                <div class="name">{{item.goods_name}}</div>
                <div class="price">¥ {{item.sale_price}}</div>
                <div class="sales-volume">已售 <span>{{item.sale_count}}</span> 件</div>
              </div>
            </li>
          </ul>
          <div class="more-btn" @click="toMoreBagSingle">查看更多单品</div>
        </div>
      </div>
      <div class="package-wrap">
        <div class="package-title">网红爆款，让你的家紧跟时代潮流</div>
        <div class="product-wrap">
          <ul>
            <li v-for="(item, index) in netRedItem" :key="index" @click="toProductDetail(item)">
              <div class="pic">
                <img :src="item.file_path" alt="">
              </div>
              <div class="detail">
                <div class="name">{{item.goods_name}}</div>
                <div class="price">¥ {{item.sale_price}}</div>
                <div class="sales-volume">已售 <span>{{item.sale_count}}</span> 件</div>
              </div>
            </li>
          </ul>
          <div class="more-btn" @click="toMoreStarSame">查看更多爆款</div>
        </div>
      </div>
      <div class="package-wrap" v-if="false">
        <div class="package-title">参考实况案例，帮助你形成更为立体的认知</div>
        <div class="package-swiper">
          <div class="swiper-container reference-case">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in referenceCase" :key="index">
                <div class="pic">
                  <img :src="item.img" alt="">
                </div>
                <div class="detial">
                  <div class="name">{{item.name}}</div>
                  <div class="info">{{item.hourseType}} | {{item.measureArea}} | {{item.style}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-button-next reference-case-next"></div>
          <div class="swiper-button-prev reference-case-prev"></div>
        </div>
        <div class="more-btn">查看更多套餐</div>
      </div>
    </div>
    <right></right>
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import headerNav from "@/components/Navbar";
  import Swiper from "swiper/swiper-bundle.min";
  import 'swiper/swiper-bundle.min.css';
  import right from "@/components/Right"; 
  import footerNav from "@/components/Footer";
  import dump from "@/util/dump";
  export default {
    components: {
      headerNav,
      footerNav,
      right
    },
    data () {
      return {
        selectInfo: {
          title: "搜拎包单品",
          id: 1
        },
        selectInfoList: [
          {
            title: "搜拎包单品",
            id: 1
          },
          {
            title: "搜拎包套餐",
            id: 2
          },
          {
            title: "搜明星同款",
            id: 3
          },
          // {
          //   title: "搜落地方案",
          //   id: 4
          // }
        ],
        banners: [],
        keyWords: "",
        keyWordsList: [
          "现代客厅",
          "卧室",
          "极简"
        ],
        searchPlaceholder: this.$t('homeSearchInputPlaceholder'),
        classifyList: [
          {
            img: require("@/../public/img/classify-list-1.png"),
            link: "/bagCheck"
          },
          {
            img: require("@/../public/img/classify-list-2.png"),
            link: "/bagCheckSingle"
          },
          {
            img: require("@/../public/img/classify-list-3.png"),
            link: "/starSameStyle"
          },
          // {
          //   img: require("@/../public/img/classify-list-4.png"),
          //   link: "#"
          // }
        ],
        packageSlide: [],
        customizationItems: [],
        netRedItem: [],
        referenceCase: [
          {
            img: require("@/../public/img/package-slide-1.png"),
            name: "35号套餐-新中式风格套餐1",
            hourseType: "两房两厅",
            measureArea: "建筑 109.00㎡ 套内 87.38㎡",
            style: "中式",
          },
          {
            img: require("@/../public/img/package-slide-2.png"),
            name: "儒雅东方",
            hourseType: "两房两厅",
            measureArea: "建筑 121.00㎡ 套内 96.73㎡",
            style: "中式",
          },
          {
            img: require("@/../public/img/package-slide-3.png"),
            name: "恒福美地花园",
            hourseType: "两房两厅",
            measureArea: "建筑 115.00㎡ 套内 92.35㎡",
            style: "极简",
          },
          {
            img: require("@/../public/img/package-slide-4.png"),
            name: "韶华之梦",
            hourseType: "两房两厅",
            measureArea: "建筑 116.00㎡ 套内 93.08㎡",
            style: "轻奢",
          },
          {
            img: require("@/../public/img/package-slide-4.png"),
            name: "韶华之梦",
            hourseType: "两房两厅",
            measureArea: "建筑 116.00㎡ 套内 93.08㎡",
            style: "轻奢",
          },
          {
            img: require("@/../public/img/package-slide-2.png"),
            name: "儒雅东方",
            hourseType: "两房两厅",
            measureArea: "建筑 121.00㎡ 套内 96.73㎡",
            style: "中式",
          },
          {
            img: require("@/../public/img/package-slide-3.png"),
            name: "恒福美地花园",
            hourseType: "两房两厅",
            measureArea: "建筑 115.00㎡ 套内 92.35㎡",
            style: "极简",
          }
        ]
      }
    },
    created(){
      this.getBanners();
      this.getBagSetmeals();
      this.getBagSingle();
      this.getStarSame();
    },
    mounted() {
      
      
      new Swiper('.reference-case', {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 10,
        // loop: true,
        // loopFillGroupWithBlank: true,
        navigation: {
          nextEl: ".reference-case-next",
          prevEl: ".reference-case-prev",
        },
      });
    },
    methods: {
      toItemLink(link){
        dump.link({
          type: 1,
          link
        })
      },
      toMorePackage(){
        dump.link({
          type: 1,
          link: "/bagCheck"
        })
      },
      // 获取banner
      getBanners(){
        this.$api.getbanners({banner_type: 16}).then(res => {
          this.banners = res.data;
        }).catch(err => console.error(err))
      },
      // 拎包套餐
      getBagSetmeals(){
        this.$api.bagSetmeals({}).then(res => {
          console.log(res)
          this.packageSlide = res.data.setmeals;
          this.$nextTick(() => {
            this.packageSwiper();
          })
        }).catch(err => console.error(err))
      },
      // 拎包单品
      getBagSingle(){
        this.$api.getGoods({bag_type: 1}).then(res => {
          this.customizationItems = res.data.goods;
        }).catch(err => console.error(err))
      },
      // 明星同款
      getStarSame(){
        this.$api.getGoods({star_type: 1}).then(res => {
          this.netRedItem = res.data.goods;
        }).catch(err => console.error(err))
      },
      packageSwiper(){
        new Swiper('.package-con', {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 10,
          // loop: true,
          // loopFillGroupWithBlank: true,
          navigation: {
            nextEl: ".package-slide-next",
            prevEl: ".package-slide-prev",
          },
        });
      },
      toMoreBagSingle(){
        dump.link({
          type: 1,
          link: "/bagCheckSingle"
        })
      },
      toMoreStarSame(){
        dump.link({
          type: 1,
          link: "/starSameStyle"
        })
      },
      toProductDetail(obj){
        dump.link({
          type: 1,
          link: "/product/details",
          params: {
            good_id: obj.goods_id
          }
        })
      },
      // 搜索
      search(){
        const data = {
          type: 3,
          link: '/bagCheckSingle',
          params: {
            keyWords: this.keyWords
          }
        };
        if(this.selectInfo.id == 1){
          // 搜拎包单品
          data.link = "/bagCheckSingle"
          
          
        }else if(this.selectInfo.id == 2){
          // 搜拎包套餐 
          data.link = "/bagCheck"
          
        }else if(this.selectInfo.id == 3){
          // 搜明星同款
          data.link = "/starSameStyle"
          
        }
        dump.link(data);
      },
      // 跳转到套餐详情
      toPackageDetail(obj){
        dump.link({
          type: 3,
          link: "/bagCheckDetail",
          params: {
            setmeal_id: obj.setmeal_id
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "index";
</style>